.game {
    &-ctr {
        border: 3px solid var(--amplify-colors-neutral-80);
        border-radius: 5px;
        padding: 1rem;
    }
    &-mc {
        &-description {
            font-size: 16px;
        }

        &-btn {
            
        }

        &-dropctr {
            border: 3px solid var(--amplify-colors-neutral-80);
            border-radius: 5px;
        }

        &-item {
            // background-color: var(--amplify-colors-neutral-40);
            border: 1px solid var(--amplify-colors-neutral-60);
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            font-size: 16px;
            padding: 1rem;
        }

        &-sel {
            background-color: var(--amplify-colors-blue-20);
        }
    }

    &-oe {
        &-title {

        }

        &-description {

        }

        &-button {

        }

        &-dropctr {
            border: 3px solid var(--amplify-colors-neutral-80);
            border-radius: 5px;
        }

        &-dz {
            width: 100%;
            background-color: var(--amplify-colors-white);
            border: 1px solid var(--amplify-colors-neutral-20);
            border-radius: 5px;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 5px;
            display: flex;
            flex-direction: column;
            min-height: 30px;
            font-size: 14px;
            padding: 5px;
        }

        &-item {
            // background-color: var(--amplify-colors-neutral-40);
            border: 1px solid var(--amplify-colors-neutral-40);
            text-align: center;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            font-size: 16px;
            padding: 1rem;
        }

        &-handle {
            text-align: center;
        }
    }

    &-pt {
        &-title {

        }

        &-description {

        }

        &-button {

        }

        &-dropctr {
            border: 3px solid var(--amplify-colors-neutral-80);
            border-radius: 5px;
        }

        &-dz {
            width: 100%;
            background-color: var(--amplify-colors-white);
            border: 1px solid var(--amplify-colors-neutral-20);
            border-radius: 5px;
            margin-top: auto;
            margin-bottom: auto;
            padding-left: 5px;
            display: flex;
            flex-direction: column;
            min-height: 30px;
            font-size: 14px;
            padding: 5px;
        }

        &-item {
            // background-color: var(--amplify-colors-neutral-40);
            border: 1px solid var(--amplify-colors-neutral-40);
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            font-size: 14px;
        }
    }

    &-ak {
        &-success {
            background-color: #189B66;
            color: var(--amplify-colors-white);

            &-text {
                color: #189B66;
            }
        }

        &-error {
            background-color: #cc4013;
            color: var(--amplify-colors-white);

            &-text {
                color: #cc4013;
            }
        }

        &-border-success {
            border: 3px solid #189B66
        }

        &-border-error {
            border: 3px solid #cc4013
        }
    }
}