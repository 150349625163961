// @import '~@ripedata/ididio-definitions/src/common';

.dp {
  padding: 10px;
  background-color: var(--amplify-colors-neutral-40);
  height: 100%;
  display: flex;
  flex-direction: column;
  // font-size: 20px;

  &-label {
    color: var(--amplify-colors-neutral-80);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-src {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    &-nm {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      flex-grow: 0;
      flex-shrink: 0;

      &-label {
        color: var(--amplify-colors-neutral-80);
        font-size: 16px;
        font-weight: 600;
        // font-weight: bold;
      }

      &-icon {
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
        color: var(--amplify-colors-neutral-80);

        &-close {
          margin-left: auto;
        }
      }
    }
  }

  &-flds {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    &-label {
      font-size: 16px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &-lst {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
    }

    &-hdr {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      &-label {
        color: var(--amplify-colors-neutral-80);
        font-size: 14px;
        font-weight: bold;
        margin-right: 10px;
        flex-grow: 0;
        flex-shrink: 0;
      }

      &-srch {
        align-items: center;
        // flex-grow: 1;
        // flex-shrink: 1;
      }
    }
  }

  &-fld {
    background-color: var(--amplify-colors-white);
    border: 1px solid var(--amplify-colors-neutral-10);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-right: 3px;
    margin-bottom: 5px;
    padding: 3px;
    cursor: pointer;
    // font-size: 14;

    &-type {
      font-weight: bold;
      font-size: 14;
      margin-left: 5px;
      margin-right: 5px;
    }

    &-name {
      font-size: 14px;
    }
  }

  &-sel {
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    &-label {
      color: var(--amplify-colors-neutral-80);
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-left: 5px;
      flex-grow: 0;
      flex-shrink: 0;
      text-align: center;

      &-cont {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
      }
    }

    &-list {
      // background-color: var(--amplify-colors-white)
      flex-grow: 1;
      flex-shrink: 1;
      overflow: auto;
      padding: 0px 5px;
    }

    &-item {
      padding: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      // border-bottom: 5px solid var(--amplify-colors-neutral-40);
      border-radius: 5px;
      background-color: var(--amplify-colors-white);
      cursor: pointer;

      &-chk {
        margin-right: 10px;
        margin-top: auto;
        margin-bottom: auto;
      }

      &-title {

      }
    }
  }

  &-si {
    display: flex;
    flex-direction: row;
    background-color: var(--amplify-colors-white);
    border: 1px solid var(--amplify-colors-white);
    border-radius: 10px;
    padding: 2px;

    &-val {
      margin-left: 5px;
      // margin-bottom: 2px;
      transition: width 3s;
      border-width: 0px;
    }

    &-inp {

    }

    &-icn {
      width: 14px;
      height: 14px;
      margin-right: 2px;
      margin-left: 2px;
      margin-bottom: 2px;
      margin-top: -2px;
    }
  }
}
