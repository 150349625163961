.dnd {
  &-confirmmodal {
    --width: 250px;
    --height: 120px;
  
    display: flex;
    flex-direction: column;
    width: var(--width);
    height: var(--height);
    position: fixed;
    top: calc(100vh / 2 - var(--height) / 2);
    left: calc(100vw / 2 - var(--width) / 2);
    border-radius: 10px;
    box-shadow: -1px 0 15px 0 rgba(34, 33, 81, 0.01),
      0px 15px 15px 0 rgba(34, 33, 81, 0.25);
    padding: 15px;
    box-sizing: border-box;
    background-color: white;
    text-align: center;
    z-index: 1;
  
    h1 {
      flex: 1;
      font-size: 16px;
      font-weight: normal;
      line-height: 20px;
    }
  
    button {
      width: 50px;
      height: 23px;
      background: #242836;
      color: #f6f8ff;
      border: none;
      border-radius: 3px;
      margin: 0 5px;
      cursor: pointer;
  
      &:hover {
        background-color: #2f3545;
      }
    }
  }
}

