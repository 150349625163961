// @import '~@ripedata/ididio-definitions/src/common';

.vcd {
  &-container {
    overflow: auto;
    margin-bottom: 10px;
    width: 100%;
  }

  &-title {
    font-size: 20px;
    // font-weight: bold;
    text-align: center;
  }

  &-ctl {
    margin: 20px auto;
    max-width: 400px;

    &-itm {
      margin-bottom: 10px;
    }
  }

  &-chart {
    width: 100%;
  }
}

.vega-container,
.vega-container::after,
.vega-container::before {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.vega-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
