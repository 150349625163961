.dnd {

  &-wrapper {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    justify-content: flex-start;

    &.center {
      justify-content: center;
    }
  }
}
