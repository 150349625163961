$font-weight: 400;
$background-color: #fefefe;
$border-color: #efefef;
$text-color: #333;
$handle-color: rgba(0, 0, 0, 0.25);
$box-shadow-border: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05);
$box-shadow-common: 0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
$box-shadow: $box-shadow-border, $box-shadow-common;
$focused-outline-color: #4c9ffe;

.oe {

    &-dropctr {
        border: 2px solid var(--amplify-colors-neutral-80);
        border-radius: 5px;
    }

    &-dz {
        width: 100%;
        background-color: var(--amplify-colors-white);
        border: 1px solid var(--amplify-colors-neutral-20);
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        min-height: 30px;
        font-size: 14px;
        padding: 5px;
    }

    &-item {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
        // padding: 18px 20px;
        margin-bottom: 5px;
        background-color: #fff;
        box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
          0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
        border-radius: calc(4px / var(--scale-x, 1));
        box-sizing: border-box;
        list-style: none;
        color: #333;
        font-weight: 400;
        font-size: 1rem;
        font-family: sans-serif;

        &.fadeIn {
          animation: fadeIn 500ms ease;
        }
    }

    &-handle {
      display: flex;
      // width: 12px;
      width: 100%;
      padding: 15px;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      touch-action: none;
      border-radius: 5px;
      border: none;
      outline: none;
      appearance: none;
      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
      text-align: center;
    }

    &-label {
      width: 100%;
      text-align: center;
      color: var(--amplify-colors-neutral-60);

    }

    &-connector {
      position: absolute;
      right: 50%;
      left: 50%;
      top: 100px;
      width: 0;
      height: 100%;
      border: 2 solid var(--amplify-colors-neutral-60);
    }

    &-dragitem {
      position: relative;
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: center;
      padding: 18px 20px;
      background-color: $background-color;
      box-shadow: $box-shadow;
      outline: none;
      border-radius: calc(4px / var(--scale-x, 1));
      box-sizing: border-box;
      list-style: none;
      transform-origin: 50% 50%;
  
      -webkit-tap-highlight-color: transparent;
  
      color: $text-color;
      font-weight: $font-weight;
      font-size: 1rem;
      // white-space: nowrap;
  
      transform: scale(var(--scale, 1));
      transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
  
      &:focus-visible {
        box-shadow: 0 0px 4px 1px $focused-outline-color, $box-shadow;
      }
  
      &:not(.withhandle) {
        touch-action: manipulation;
        cursor: grab;
      }
  
      &-dragging {
      
        z-index: 1;
        transition: none;
  
        * {
          cursor: grabbing;
        }
        
        &:not(.dragOverlay) {
          opacity: var(--dragging-opacity, 0.3);
          z-index: 0;
  
          &:focus {
            box-shadow: $box-shadow;
          }
        }
      }
  
      &-disabled {
        // color: #999;
        // background-color: #f1f1f1;
        &:focus {
          box-shadow: 0 0px 4px 1px rgba(0, 0, 0, 0.1), $box-shadow;
        }
        // cursor: not-allowed;
        cursor: pointer;
      }
  
      &-dragoverlay {
        cursor: inherit;
        // box-shadow: 0 0px 6px 2px $focused-outline-color;
        animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
        transform: scale(var(--scale));
        box-shadow: var(--box-shadow-picked-up);
        opacity: 1;
      }
  
      &-color:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 100%;
        width: 3px;
        display: block;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: var(--color);
      }
  
      &:hover {
        .Remove {
          visibility: visible;
        }
      }
  
      &-remove {
        visibility: hidden;
      }
  
      &-actions {
        display: flex;
        align-self: flex-start;
        margin-top: -12px;
        margin-left: auto;
        margin-bottom: -15px;
        margin-right: -10px;
      }
  
      &-wrapper {
        display: flex;
        box-sizing: border-box;
        transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0)
          scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
        transform-origin: 0 0;
        touch-action: manipulation;
  
        &-fadein {
          animation: fadeIn 500ms ease;
        }
  
        &-dragoverlay {
          --scale: 1.05;
          --box-shadow: $box-shadow;
          --box-shadow-picked-up: $box-shadow-border,
            -1px 0 15px 0 rgba(34, 33, 81, 0.01),
            0px 15px 15px 0 rgba(34, 33, 81, 0.25);
          z-index: 999;
        }
      }

      &-ttp {
        background: var(--amplify-colors-teal-60);
        color: white;
        position: absolute;
        margin: auto;
        border: 2px solid var(--amplify-colors-neutral-20);
        border-radius: 14px;
        padding: 0px 9px 2px 9px;
        cursor: pointer;
      }

      &-status {
        color: white;
        position: absolute;
        margin: auto;
        top: -12px;
        border-radius: 14px;
        padding: 0px 9px 2px 9px;
        font-size: xs;

        &-initial {
          background: var(--amplify-colors-neutral-60);
        }

        &-correct {
          background: var(--amplify-colors-neutral-60);
        }

        &-wrong {
          background: var(--amplify-colors-neutral-60);
        }
      }

      &-msg {
        font-size: smaller;
      }
    }

    &-prg {

      &-item {
        border-radius: 5px;
        // margin: 2px;
        // padding: 2px 7px;
        width: 100%;
        height: 12px;
        background-color: var(--amplify-colors-neutral-40);

        &-current {
          border: 2px solid var(--amplify-colors-neutral-80);
        }
      }

      &-label {
        font-size: small;
        margin: 2px;
        padding: 5px;
      }
    }
}

// @keyframes pop {
//   0% {
//     transform: scale(1);
//     box-shadow: var(--box-shadow);
//   }
//   100% {
//     transform: scale(var(--scale));
//     box-shadow: var(--box-shadow-picked-up);
//   }
// }

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// .SortableItem {
//     display: flex;
//     justify-content: space-between;
//     flex-grow: 1;
//     align-items: center;
//     padding: 18px 20px;
//     background-color: #fff;
//     box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
//       0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
//     border-radius: calc(4px / var(--scale-x, 1));
//     box-sizing: border-box;
//     list-style: none;
//     color: #333;
//     font-weight: 400;
//     font-size: 1rem;
//     font-family: sans-serif;
//   }
  
//   .DragHandle {
//     display: flex;
//     // width: 12px;
//     width: 100%;
//     padding: 15px;
//     align-items: center;
//     justify-content: center;
//     flex: 0 0 auto;
//     touch-action: none;
//     cursor: var(--cursor, pointer);
//     border-radius: 5px;
//     border: none;
//     outline: none;
//     appearance: none;
//     background-color: transparent;
//     -webkit-tap-highlight-color: transparent;
//   }
  
//   .DragHandle:hover {
//     background-color: rgba(0, 0, 0, 0.05);
//   }
  
//   .DragHandle:focus-visible {
//     box-shadow: 0 0px 0px 2px #4c9ffe;
//   }
  
//   .DragHandle svg {
//     flex: 0 0 auto;
//     margin: auto;
//     height: 100%;
//     overflow: visible;
//     fill: #919eab;
//   }
  