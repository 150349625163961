// @import '~@ripedata/ididio-definitions/src/common';

.gp {
  padding: 10px;
  background-color: var(--amplify-colors-neutral-20);
  font-size: 20px;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &-ctr {
    display: flex;
    flex-direction: column;
    // flex-grow: 1;
    // flex-shrink: 1;
    overflow: auto;
  }

  &-ctls {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    // overflow: auto;
  }

  &-label {
    color: var(--amplify-colors-neutral-80);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &-typ {
    display: flex;
    flex-direction: column;

    &-label {
      font-size: 14px;
    }

    &-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      grid-gap: 5px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &-btn {
      width: 35px;
      font-size: 10px;
      line-height: 11px;
      text-align: center;
    }
  }

  &-grp {
    padding-top: 5px;
    margin-bottom: 5px;
    border-top: 2px var(--amplify-colors-neutral-40) solid;
    font-size: 14px;
    display: flex;
    flex-direction: row;

    &-ctr {
      flex-grow: 1;
      flex-shrink: 1;
    }

    &-cnt {
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      cursor: pointer;
    }

    &-lbl {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 16px;
      margin-bottom: 5px;
    }

    &-icn {
      width: 16px;
      height: 16px;
      margin: 4px 5px;
      right: 0px;
      cursor: pointer;
    }
  }

  // Axis configuration
  &-fc {
    background-color: var(--amplify-colors-neutral-40);
    border: 1px solid var(--amplify-colors-neutral-40);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    font-size: 14px;

    &-icn {
      width: 14px;
      height: 14px;
      margin: 4px 5px;
      cursor: pointer;
    }

    &-fld {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-lbl {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &-prp {
      margin-top: 5px;
    }

    &-ctr {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &-cfg {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    &-drp {
      background-color: var(--amplify-colors-neutral-40);
      border: 1px solid var(--amplify-colors-neutral-40);
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      font-size: 14px;

      &-lbl {
        width: 75px;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 5px;
        cursor: default;
      }

      &-fld {
        width: 100%;
        background-color: var(--amplify-colors-white);
        border: 1px solid var(--amplify-colors-neutral-20);
        border-radius: 5px;
        margin-top: auto;
        margin-bottom: auto;
        padding-left: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: default;

        &-dflt {
          color: var(--amplify-colors-neutral-60);
        }

        &-val {
          width: 100%;
        }

      }

      &-icn {
        width: 16px;
        height: 16px;
        margin: auto 5px;
        right: 0px;
        cursor: pointer;
      }
    }

  }

  &-fld {
    background-color: var(--amplify-colors-white);
    border: 1px solid var(--amplify-colors-neutral-20);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    cursor: pointer;

    &-type {
      font-weight: bold;
      font-size: 16;
      margin-left: 5px;
      margin-right: 5px;
    }

    &-name {

    }
  }

  &-sctn {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    cursor: pointer;

    &-lbl {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 16px;
    }

    &-icn {
      margin-right: 0px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &-mf {
    &-dz {
      width: 100%;
      background-color: var(--amplify-colors-white);
      border: 1px solid var(--amplify-colors-neutral-20);
      border-radius: 5px;
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      min-height: 30px;
      font-size: 14px;
      padding: 5px;
    }

    &-itm {
      display: flex;
      flex-direction: row;
      background-color: var(--amplify-colors-white);
      border: 1px solid var(--amplify-colors-neutral-20);
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: move;

      &-icn {
        width: 14px;
        height: 14px;
        margin: 7px 5px;
        cursor: pointer;
      }

      &-lbl {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 1;
      }

      &-prp {
        margin-top: 5px;
      }

      &-ctr {
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  &-mf {
    &-dz {
      width: 100%;
      background-color: var(--amplify-colors-white);
      border: 1px solid var(--amplify-colors-neutral-20);
      border-radius: 5px;
      margin-top: auto;
      margin-bottom: auto;
      padding-left: 5px;
      display: flex;
      flex-direction: column;
      min-height: 30px;
      font-size: 14px;
      padding: 5px;
    }
  }

  // Controls inside the configuration panel
  &-ctl {
    display: flex;
    flex-direction: row;
    padding-right: 10px;
    padding-bottom: 5px;

    &-lbl {
      flex-grow: 0;
      flex-shrink: 0;
      margin-right: 5px;
      font-weight: normal;
    }

    &-val {
      flex-grow: 1;
      flex-shrink: 1;
      background-color: var(--amplify-colors-white);
      border-style: none;
    }

    &-icn {
      width: 14px;
      height: 14px;
      cursor: pointer;
    }

    &-clrblk {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: auto;
      margin-left: 5px;
      width: 4rem;
      height: 1.5rem;
      cursor: pointer;
      border: 1 var(--amplify-colors-white) solid;
    }
  }

  // Axis configuration
  &-xs {
    background-color: var(--amplify-colors-neutral-40);
    border: 1px solid var(--amplify-colors-neutral-40);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    font-size: 14px;

    &-icn {
      width: 14px;
      height: 14px;
      margin: 4px 5px;
      cursor: pointer;
    }

    &-fld {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }

    &-lbl {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-shrink: 1;
    }

    &-prp {
      margin-top: 5px;
    }

    &-ctr {
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &-fcds {
    &-sel {
      background-color: var(--amplify-colors-blue-10);
    }
  }

  &-gds {
    &-bin0 {
      background-color: var(--amplify-colors-blue-10);
    }
    &-bin1 {
      background-color: var(--amplify-colors-green-10);
    }
    &-bin2 {
      background-color: var(--amplify-colors-orange-10);
    }
    &-bin3 {
      background-color: var(--amplify-colors-pink-10);
    }
    &-sel {
      border: 2px solid var(--amplify-colors-neutral-60);
    }
  }

  &-pnl {
    margin-bottom: 5px;
    font-size: 14px;
    display: flex;
    flex-direction: row;

    &-ctr {
      flex-grow: 1;
      flex-shrink: 1;
    }

    &-cnt {
      display: flex;
      flex-direction: row;
      margin: 10px 0px;
      cursor: pointer;
    }

    &-lbl {
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 16px;
      margin-bottom: 5px;
    }

    &-icn {
      width: 16px;
      height: 16px;
      margin: 4px 5px;
      right: 0px;
      cursor: pointer;
    }
  }
}



.cp button.type-item {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
  vertical-align: middle;
  font-size: x-small;
  line-height: .8rem;
  width: 50px;
}

.cp button.type-item i.format {
  background-size: contain;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-top: 2px;
  vertical-align: -0.25em;
  display: flex;
  opacity: 0.6;
}

i.cp-bar {
  background-image: url(./icons/bar.svg);
}
  
i.cp-beeswarm {
  background-image: url(./icons/beeswarm.svg);
}

i.cp-pcoord {
  background-image: url(./icons/pcoord.svg);
}

i.cp-scatter {
  background-image: url(./icons/scatter.svg);
}