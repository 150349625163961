
.slider {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  &-container {
    flex-grow: 1;
    flex-shrink: 1;
    margin-top: 1.25rem;
  }

  &-inline {
    flex-direction: row;
  }

  &-label {
    // @extend %text-body-copy;
    font-weight: bold;
    margin-bottom: 10px;

    &-inline {
      margin-top: auto;
      margin-bottom: auto;
      margin-right: 20px;
    }
  }
}
