
.ved {
  background-color: var(--amplify-colors-white);
  // padding: 20px 0px 2px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;

  &-ctls {
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    &-btnactn {
      margin-left: 10px;
      padding: 5px 10px;
      border: 3px solid "#f47e39"-orange;
      background: "#f47e39"-orange;
      color: var(--amplify-colors-white);
      border-radius: 5px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }

    &-btn {
      margin-left: 10px;
      padding: 5px 10px;
      border: 3px solid var(--amplify-colors-neutral-50);
      background: var(--amplify-colors-neutral-50);
      color: var(--amplify-colors-white);
      border-radius: 5px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &-wksp {
    flex-grow: 1;
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;

    &-data {
      width: 300px;
      background-color:  var(--amplify-colors-neutral-60);
    }

    &-spec {
      width: 300px;
      background-color: var(--amplify-colors-neutral-20);
    }
  }
}
